import { useAuthStore } from '~/store/auth'
import dayjs from 'dayjs'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const cookie = useCookie('accessToken', {
    expires: dayjs().add(90, 'day').toDate(),
  })
  const app = useNuxtApp()
  app.$native.log(`[auth middleware]${cookie.value}`)

  if (process.client) {
    const authStore = useAuthStore()

    if (authStore.user) {
      return true
    }

    const user = await authStore.syncUser()

    if (!user) {
      if (from.fullPath.search(/\?/) > -1) {
        return navigateTo(`/auth/login`)
      } else {
        cookie.value = null
        return navigateTo(
          `/auth/login?redirect=${encodeURIComponent(from.fullPath)}`,
        )
      }
    }
  }

  return true
})
